import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SignInForm from "../components/SignIn"
import * as ROUTES from "../constants/routes"

export default () => (
  <Layout activePage="signin">
    <section className="page_login">
      <div className="wrapper">
        <div className="row centered">
          <div className="col col-4">
            <h1 className="heading--l thin">Sign In</h1>
            <SignInForm />
            <p className="body--s login-link">
              <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
            </p>
            <p className="body--s login-link">
              Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

import React, { Component } from "react"
import { navigate } from "gatsby"

import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email, password } = this.state

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        navigate(ROUTES.QUESTIONNAIRE)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error } = this.state

    const isInvalid = password === "" || email === ""

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-field">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </div>
        <div className="input-field">
          <label htmlFor="password">Password</label>
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
        </div>
        <div className="input-field">
          <button className="btn" disabled={isInvalid} type="submit">
            <span>Sign In</span>
          </button>
        </div>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase)

export default SignInForm
